import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  MdxWrapper,
  YouTube,
  PressOfficeWidget,
} from '../../components'

import Layout from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'

const shortcodes = {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  YouTube,
}

/**
 * @todo (mdx):
 * Add fields to GraphQL schema
 * export partials that don't use MDX for reuse in CMS previews
 */

export const KitchenSinkTemplate = ({ editor }) => (
  <MDXProvider components={shortcodes}>
    <MdxWrapper>
      <MDXRenderer>{editor}</MDXRenderer>
    </MdxWrapper>
  </MDXProvider>
)

KitchenSinkTemplate.propTypes = {
  editor: PropTypes.string,
}

const KitchenSink = ({ data }) => {
  const {
    frontmatter: { editor },
  } = data.mdx

  return (
    <Layout>
      <div>
        <KitchenSinkTemplate editor={editor} />
      </div>
      <div>
        <PressOfficeWidget />
      </div>
    </Layout>
  )
}

KitchenSink.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default KitchenSink

export const pageQuery = graphql`
  query KitchenSinkTemplate {
    mdx(frontmatter: { templateKey: { eq: "KitchenSink" } }) {
      frontmatter {
        editor
      }
    }
  }
`
