import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Navbar from '../../partials/NavBar/Navbar'
import useSiteMetadata from '../../graphqlFragments/SiteMetadata'
import Head from '../Head'
import { Footer } from '../..'
import styles from './ContentRightSidebar.module.css'
import headerProps from '../../propTypes/headerProps'
import PrimaryHeader from '../../elements/PrimaryHeader/PrimaryHeader'

const ContentRightSidebar = ({ header, children }) => {
  const { title, description } = useSiteMetadata()
  const classes = classNames(['page-wrapper container', styles.root])
  const headerProps = !header
    ? null
    : {
        ...header,
        image: header.backgroundUrl,
      }

  return (
    <>
      <Head title={title} description={description} />
      <Navbar />
      {header && <PrimaryHeader {...headerProps} />}
      <div className={classes}>{children}</div>
      <Footer />
    </>
  )
}

ContentRightSidebar.propTypes = {
  header: headerProps,
  children: PropTypes.node,
}

export default ContentRightSidebar
